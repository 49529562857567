import { useState, createContext } from 'react';
import { ResizableContextType } from 'types';

export const ResizableContext = createContext<ResizableContextType | null>(null);

const ResizableProvider = ({ children }) => {
  const minWidth = 400;
  const [width, setWidth] = useState(minWidth);

  return <ResizableContext.Provider value={{ width, setWidth, minWidth }}>{children}</ResizableContext.Provider>;
};

export default ResizableProvider;
