import { BlobStorageCategory } from 'components/constants/storage-type-enum';
import fileService from 'services/file-service';

export const CLEAN_SITE_FILES_REQUEST = "CLEAN_SITE_FILES_REQUEST";
export const CLEAN_SITE_FILES_SUCCESS = "CLEAN_SITE_FILES_SUCCESS";
export const CLEAN_SITE_FILES_FAILURE = "CLEAN_SITE_FILES_FAILURE";

export const cleanSiteFilesAction = (siteId) => async (dispatch) => {
    dispatch({ type: CLEAN_SITE_FILES_REQUEST });

    try {
        await fileService.cleanSiteFiles(siteId, BlobStorageCategory.EXPORT);
        dispatch({ type: CLEAN_SITE_FILES_SUCCESS });
    } catch (error: any) {
        const errorMessage = error instanceof Error ? error.message : "An unknown error occurred";
        dispatch({ type: CLEAN_SITE_FILES_FAILURE, payload: errorMessage });
    }
};