import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import {Tabs, Tab, Button, IconButton} from '@mui/material';
import { bindActionCreators } from 'redux';
import { useAppDispatch } from 'store';
import { clearProjectFilesAction, getProjectFilesAction } from 'store/actions/file-download-actions';
import { RootState } from 'store/reducers';
import BrowserDownloadTab from './browser-download-tab/browser-download-tab';
import CommandLineDownloadTab from './command-line-download-tab/command-line-download-tab';
import TablePagination from '@mui/material/TablePagination';
import DeleteUserIcon from "@mui/icons-material/Delete";
import styles from 'components/user/user-delete-button.module.scss';
import ConfirmDialog from 'components/shared/confirm-dialog/confirm-dialog';
import { cleanSiteFilesAction } from 'store/actions/file-clean-actions';

import './project-download.scss';

const mapState = (state: RootState) => ({
  fileClean: state.fileCleanReducer,
  projectFiles: state.fileDownloadReducer.projectFiles,
  continuationToken: state.fileDownloadReducer.continuationToken,
  pageSize: state.fileDownloadReducer.pageSize,
  lastItemIndex : state.fileDownloadReducer.lastItemIndex,
});

const mapDispatch = (dispatch) => bindActionCreators(
  {
    clearProjectFilesAction,
  },
  dispatch
);

const connector = connect(mapState, mapDispatch);

type ReduxProps = ConnectedProps<typeof connector>;

type OwnProps = {
  siteId: number,
};

type Props = ReduxProps & OwnProps;

const ProjectDownload = ({
  siteId,
  projectFiles,
  fileClean,
  continuationToken,
  lastItemIndex,
  pageSize,
  clearProjectFilesAction,
}: Props) => {
  
  const [sId, setsId] = useState<number>(siteId);
  const dispatch = useAppDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);

  const [previousTokens, setPreviousTokens] = useState<{token: string | null; lastItemIndex : number}[]>([]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newRowsPerPage =  parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage)
    setPage(0);
    setPreviousTokens([]);
    dispatch(getProjectFilesAction(sId, null, -1, newRowsPerPage));
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (newPage > page){
      if (continuationToken){
        setPreviousTokens((prevTokens)=>[...prevTokens, {token: continuationToken, lastItemIndex: lastItemIndex}]);
        dispatch(getProjectFilesAction(sId, continuationToken, lastItemIndex, rowsPerPage));
      }
    }
    else {
      if (previousTokens.length > 0) {
        const updatedTokens = previousTokens.slice(0, previousTokens.length - 1);
        const previousToken = updatedTokens[updatedTokens.length - 1] || { token: null, lastItemIndex: -1 };
        setPreviousTokens(updatedTokens);
        dispatch(getProjectFilesAction(sId, previousToken.token, previousToken.lastItemIndex, rowsPerPage));
      }
    }
    setPage(newPage);
  };

  useEffect(() => {
    setsId(siteId);
    dispatch(getProjectFilesAction(sId, null, -1, rowsPerPage));
    return () => {
      clearProjectFilesAction();
    };
  }, [siteId, sId, getProjectFilesAction, clearProjectFilesAction, fileClean.loading]);

  const cleanSiteFiles = () => {
    dispatch(cleanSiteFilesAction(siteId));
  };

  return (
    <>
      <div className='project-download'>
        <div className='title'>DOWNLOAD</div>
          <div className='container'>
            <div className='description'>
              <div>To make files available for download, please move them to the /export using one of your sessions.</div>
              <div>Files in the /export directory will be removed automatically after a few days.</div>
              <div>Once moved/copied to this directory, the file will appear below and will be available for download or
                be shared through a temporary link to an email of your choosing
              </div>
              <br/>
              <div><strong>BROWSER</strong> download is the right choice if you want to download
                a <strong>specific</strong> file
              </div>
              <div><strong>COMMAND LINE</strong> download is best suited if you want to download <strong>all</strong> of
                the files or for <strong>large</strong> files that take hours to download.
              </div>
            </div>

            <Tabs value={tabIndex} onChange={(e, newValue) => setTabIndex(newValue)}>
              <Tab label='BROWSER'/>
              <Tab label='COMMAND LINE'/>
                <ConfirmDialog
                    title='Clear my export directory'
                    text='Are you sure you want to clear all export directory files?'
                    isMenuItem={false}
                    onConfirm={async () => cleanSiteFiles()}>
                  <Button className='menu-button-mail'>
                    <IconButton className={styles.delete_button__icon_button}>
                      <DeleteUserIcon className={styles.delete_button__action_icon}/>
                    </IconButton>
                    <span className='menu-button-text-mail'>Clear my export directory</span>
                  </Button>
                </ConfirmDialog>
            </Tabs>

            {tabIndex === 0 && (
              <>
                <BrowserDownloadTab siteId={sId} projectFiles={projectFiles}/>
                <TablePagination
                    component='div'
                    count={-1}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage='Files per page'
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    backIconButtonProps={
                      {disabled: previousTokens.length === 0}
                    }
                    nextIconButtonProps={
                      {disabled: !continuationToken || false}
                    }
                />
              </>
            )}
            {tabIndex === 1 && (
              <>
                <CommandLineDownloadTab siteId={sId}/>
              </>
            )}
        </div>
      </div>
    </>
  );
};

export default connector(ProjectDownload);