import { useContext } from 'react';
import { ResizableContext } from 'providers/ResizableProvider';

const useResizableDiv = () => {
  const context = useContext(ResizableContext);
  if (!context) {
    throw new Error('useResizableDiv must be used within a ResizableProvider');
  }
  return context;
};

export default useResizableDiv;
