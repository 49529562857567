import { CLEAN_SITE_FILES_FAILURE, CLEAN_SITE_FILES_REQUEST, CLEAN_SITE_FILES_SUCCESS } from "store/actions/file-clean-actions";


const initialState = {
    loading: false,
    error: null,
};
  
const fileCleanReducer = (state = initialState, action) => {
    switch (action.type) {
      case CLEAN_SITE_FILES_REQUEST:
        return { ...state, loading: true, error: null };
      case CLEAN_SITE_FILES_SUCCESS:
        return { ...state, loading: false };
      case CLEAN_SITE_FILES_FAILURE:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
};

export default fileCleanReducer;