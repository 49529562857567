import DatePickerIcon from '@mui/icons-material/CalendarToday';
import KeyBoardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyBoardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import styles from './manage-users.module.scss';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useState } from 'react';
import { EXPIRATION_DATE_PREFIX, ACCES_EXPIRED_LABEL, STANDART_DATE_FORMAT } from 'components/constants//user-right-workflow-constants';

type Props = {
  filterList: any[];
  index: number;
  column: any;
  onChange: any;
};

// eslint-disable-next-line
const DatePickerFilter = ({ filterList, index, column, onChange }: Props) => {
  const [filterEnabled, setFilterEnabled] = useState(filterList[index][0] && filterList[index][0] != '');
  const initialValueParam = filterList[index][0];

  let initialValue = initialValueParam;
  if (!initialValueParam) {
    initialValue = '';
  } else if (initialValueParam.includes(EXPIRATION_DATE_PREFIX)) {
    initialValue = initialValueParam.split(EXPIRATION_DATE_PREFIX)[1];
  } else {
    initialValue = initialValueParam;
  }

  let isUnlimited = !initialValue || initialValue === 'unlimited' || initialValue === '';
  const limitDate = isUnlimited ? getFirstDayOfCurrentMonth() : initialValue;
  const limitDateParsed = limitDate.replaceAll('/', '-'); //we display dates with / but luxon wants - to parse the date
  const datePickerTextFieldVisibility = isUnlimited ? 'hidden' : 'visible';

  function getFirstDayOfCurrentMonth(): string {
    const date = new Date();
    const yearString = date.getFullYear().toString();
    const monthString = (date.getMonth() + 1).toString().padStart(2, '0');
    const dayString = '01';
    return `${yearString}/${monthString}/${dayString}`;
  }

  const toggleFilter = event => {
    setFilterEnabled(event.target.checked);
    if (filterEnabled) {
      // eslint-disable-next-line
      filterList[index][0] = '';
    } else {
      // eslint-disable-next-line
      filterList[index][0] = !limitDate ? getFirstDayOfCurrentMonth() : limitDate;
    }
    if (limitDate !== '') {
      isUnlimited = false;
    }
    onChange(filterList[index], index, column);
  };

  return (
    <>
      <FormGroup>
        <FormControlLabel control={<Checkbox checked={filterEnabled} onChange={toggleFilter} />} label={ACCES_EXPIRED_LABEL} />
      </FormGroup>

      <div className={styles.manage_user__dateContainer}>
        <DatePicker
          disabled={!filterEnabled}
          slots={{
            openPickerIcon: DatePickerIcon,
            leftArrowIcon: KeyBoardArrowLeft,
            rightArrowIcon: KeyBoardArrowRight,
          }}
          onChange={date => {
            const parsedDate = date.toFormat(STANDART_DATE_FORMAT);
            //eslint-disable-next-line
            filterList[index][0] = parsedDate;
            onChange(filterList[index], index, column);
            if (parsedDate !== '1970/01/01') {
              isUnlimited = false;
            }
          }}
          value={DateTime.fromISO(limitDateParsed)}
          maxDate={DateTime.local().minus({ days: 1 })}
          minDate={DateTime.fromISO('1970-01-01')}
          format={STANDART_DATE_FORMAT}
          timezone='Europe/Paris'
          className='user-authority-date-picker'
          slotProps={{
            textField: {
              sx: {
                '& .MuiInputBase-input.Mui-disabled': {
                  class: styles.manage_user__datePickerInputFieldDisabled,
                  visibility: datePickerTextFieldVisibility,
                },
                '.MuiOutlinedInput-input': {
                  padding: '4px 0 4px 0',
                  visibility: datePickerTextFieldVisibility,
                },
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
              },
            },
          }}
        />
      </div>
      {isUnlimited && <div className={styles.override_date_text_field}>Unlimited</div>}
    </>
  );
};

export default DatePickerFilter;
