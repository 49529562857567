import React, { useState, useEffect, useRef, PropsWithChildren } from 'react';
import './resizable-div.scss';
import useResizableDiv from 'hooks/useResizableDiv';

type Props = {
  style: {
    [key: string]: string | number;
  };
};

const ResizableDiv = ({ style = {}, children }: PropsWithChildren<Props>) => {
  const [isDragging, setIsDragging] = useState(false);
  const { width, setWidth, minWidth } = useResizableDiv();

  const rootRef = useRef<HTMLDivElement>(null);
  const isDraggingRef = useRef<boolean>(); // we use ref because isDragging does not update value on mousemove event listener callback
  isDraggingRef.current = isDragging;

  const startResize = event => {
    event.stopPropagation();

    setIsDragging(true);
  };

  useEffect(() => {
    const resizePanel = event => {
      if (isDraggingRef.current) {
        setWidth(event.clientX > minWidth ? event.clientX : minWidth);
      }
    };

    const stopResize = () => {
      if (isDraggingRef.current) {
        setIsDragging(false);
      }
    };

    const { current } = rootRef;
    document.body.addEventListener('mousemove', resizePanel);
    if (current) current.addEventListener('mouseup', stopResize);

    return () => {
      document.body.removeEventListener('mousemove', resizePanel);
      if (current) current.removeEventListener('mouseup', stopResize);
    };
  }, [minWidth]);

  return (
    <div style={{ width, overflow: 'auto' }} ref={rootRef}>
      <div className='resizable-div-root' style={style}>
        <div className='container' style={{ width }}>
          {children}
        </div>
        <div className='resizer' onMouseDown={e => startResize(e)} />
      </div>
    </div>
  );
};

export default ResizableDiv;
